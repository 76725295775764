import React from 'react';
import './App.css';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import Routes from './routes';

let theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#326273',
    },
    primary: {
      main: '#4eaaf3',
      contrastText: '#FFFFFF',
    },
    state: {
      success: '#1B9F20',
      failure: '#FF0000',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Roboto"', 'sans-serif'].join(','),
    useNextVariants: true,
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Routes />
    </MuiThemeProvider>
  );
}

export default App;
