import React from 'react';
import { CircularProgress, Grid, Typography, Button, Divider, withStyles } from '@material-ui/core';
import { Person as PersonIcon, InfoOutlined } from '@material-ui/icons';
import styles from './styles';
import PasswordResetDialog from '../PasswordReset/PasswordResetDialog';
import { SuccessToast, CustomInput, CustomPassword, PrimaryButton } from '../../components/common';
import oauthOptions from './oauthOptions';

const ERROR_LEVEL_COLORS = {
  warning: {
    color: '#856404',
    backgroundColor: '#fff3cd',
    border: '1px solid #ffeeba',
  },
  error: {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    border: '1px solid #f5c6cb',
  },
  success: {
    color: '#155724',
    backgroundColor: '#d4edda',
    border: '1px solid #c3e6cb',
  },
};

const LoginComponent = ({
  classes,
  data,
  handleChange,
  handleSubmit,
  successMessage,
  passwordResetOpen,
  handlePasswordReset,
  passwordResetSuccess,
  onCloseToast,
  redirectOauth,
}) => (
  <div className={classes.background}>
    {data.token ? (
      <div className={classes.loadingToken}>
        <CircularProgress size={60} />
        <p style={{ fontSize: 18 }}>Iniciando sesión...</p>
      </div>
    ) : (
      <>
        {data.loading && (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.gridLogin}
        >
          <form className={classes.formLogin} onSubmit={handleSubmit}>
            <img
              src="https://www.dblandit.com/img/logo-cuadrado-01.png"
              width={140}
              alt="Logo"
              style={{ margin: '0 auto' }}
            />
            <Typography component="h1" variant="h5" className={classes.title}>
              Inicio de sesión
            </Typography>
            {data.errorMessage && (
              <div className={classes.errorContainer} style={ERROR_LEVEL_COLORS[data.errorLevel]}>
                <InfoOutlined className={classes.errorIcon} />
                <div className={classes.errorMessage}>
                  {data.errorUuid && <p className={classes.errorUuid}>Código: {data.errorUuid}</p>}
                  <p className={classes.errorText}>
                    {data.errorUuid && 'Detalles:'} {data.errorMessage}
                  </p>
                </div>
              </div>
            )}
            <div>
              {oauthOptions.map(
                ({ label, backgroundColor, icon, endpoint, labelColor = '#FFF' }) => (
                  <Button
                    variant="contained"
                    startIcon={icon}
                    className={classes.oauthButton}
                    style={{ backgroundColor, color: labelColor }}
                    fullWidth
                    onClick={() => redirectOauth(endpoint)}
                  >
                    {label}
                  </Button>
                )
              )}
            </div>
            <Divider style={{ margin: '20px 0' }} />
            <div className={classes.inputLogin}>
              <CustomInput
                error={data.errorusername}
                name="username"
                label="Usuario"
                type="text"
                handleChange={handleChange}
                value={data.username}
                icon={<PersonIcon className={classes.inputIcon} />}
              />
            </div>
            <div className={classes.inputLogin}>
              <CustomPassword
                error={data.errorpassword}
                name="password"
                placeholder="Contraseña"
                handleChange={handleChange}
                value={data.password}
                variant="outlined"
              />
            </div>
            <PrimaryButton type="submit" className={classes.signInButton} onClick={handleSubmit}>
              Iniciar sesión
            </PrimaryButton>
            <div className={classes.rememberContainer}>
              <p onClick={() => handlePasswordReset(true)} className={classes.forgetPassword}>
                Olvidé mi contraseña
              </p>
            </div>
          </form>
        </Grid>
        <PasswordResetDialog
          open={passwordResetOpen}
          onClose={() => handlePasswordReset(false)}
          onSubmit={passwordResetSuccess}
        />
        <SuccessToast
          open={successMessage !== ''}
          onClose={onCloseToast('successMessage')}
          text={successMessage}
        />
      </>
    )}
  </div>
);

export default withStyles(styles)(LoginComponent);
