import React from 'react';
import { TableContainer } from '../common';

export const MESSAGES = {
  title: 'Listado de usuarios',
  created: 'El usuario ha sido creado correctamente',
  edited: 'El usuario ha sido editado correctamente',
  deleted: 'El usuario ha sido eliminado correctamente',
  deleteConfirmation: user =>
    `¿Estas seguro de que queres eliminar al usuario ${user.firstName} ${user.lastName}?`,
};

const COLUMNS = [
  {
    id: 'firstName',
    label: 'Nombre',
    minWidth: 100,
    hidden: {
      xsDown: true,
    },
  },
  {
    id: 'lastName',
    label: 'Apellido',
    minWidth: 100,
    hidden: {
      xsDown: true,
    },
  },
  {
    id: 'role',
    field: 'role',
    label: 'Rol',
    minWidth: 120,
  },
  {
    id: 'email',
    label: 'Mail',
    minWidth: 150,
    hidden: { mdDown: true },
  },
];

export const ENTITY_PROPS = {
  entityName: 'usuario',
  endpoint: 'users',
  path: '/home/users',
};

const UserTable = () => {
  return <TableContainer entityProps={ENTITY_PROPS} messages={MESSAGES} columns={COLUMNS} />;
};

export default UserTable;
