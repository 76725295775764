import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core';
import Swal from 'sweetalert2';
import { del, get, handleResponse, swal500 } from '../../../utils/network';
import TableComponent from './TableComponent';

const TableContainer = ({ entityProps, messages, columns, mock }) => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const { entityName, endpoint, path } = entityProps;
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [maxPageFetched, setMaxPageFetched] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const getData = useCallback(() => {
    get(`${endpoint}?page=0`)
      .then(res => handleResponse(res, { history }))
      .then(parsed => {
        setData(parsed.message.data);
        setCount(parsed.message.count);
        setPage(0);
        setMaxPageFetched(0);
      })
      .catch(err => swal500(err))
      .finally(() => {
        setLoading(false);
        setUpdating(false);
      });
  }, [endpoint, history]);

  const showMore = page => {
    setLoadingMore(true);
    get(`${endpoint}?page=${page}`)
      .then(res => handleResponse(res, { history }))
      .then(parsed => {
        setData(prevData => prevData.concat(parsed.message.data));
        setMaxPageFetched(page);
      })
      .catch(err => swal500(err))
      .finally(() => setLoadingMore(false));
  };

  useEffect(() => {
    if (!mock) {
      getData();
    } else {
      setData(mock);
      setCount(mock.length);
      setLoading(false);
    }
    const msgKey = location.state?.msg;
    if (msgKey) {
      const msg = messages[msgKey];
      msg && setSuccessMessage(msg);
    }
  }, [getData, location.state, messages, mock]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
    if (newPage > maxPageFetched) showMore(newPage);
  };

  const onCloseToast = () => setSuccessMessage('');

  const handleOpenForm = id => history.push(`${path}/${id || 'new'}`);

  const handleDelete = id => {
    const item = data.find(u => u._id === id);
    Swal.fire({
      title: `Eliminar ${entityName}`,
      text: messages.deleteConfirmation(item),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonText: `No, conservar ${entityName}`,
      confirmButtonText: 'Si, estoy seguro',
      width: '34em',
    }).then(result => {
      if (result.value) {
        setUpdating(true);
        del(`${endpoint}/${item._id}`)
          .then(res => handleResponse(res, { history }))
          .then(() => {
            setData(prevData => prevData.filter(r => r._id !== id));
            setCount(prevCount => prevCount - 1);
            setUpdating(false);
            setSuccessMessage(messages.deleted);
          })
          .then(() => getData())
          .catch(err => {
            setUpdating(false);
            swal500(err);
          });
      }
    });
  };

  return (
    <TableComponent
      page={page}
      loading={loading}
      updating={updating}
      data={data}
      dataCount={count}
      path={path}
      title={messages.title}
      newMessage={messages?.newButton || `Nuevo ${entityName}`}
      columns={columns}
      handleOpenForm={handleOpenForm}
      handleDelete={handleDelete}
      handleChangePage={handleChangePage}
      onCloseToast={onCloseToast}
      successMessage={successMessage}
      loadingMore={loadingMore}
    />
  );
};

export default TableContainer;
