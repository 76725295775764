import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import { TextField, IconButton, Tooltip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AddCircle, Help as HelpIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { get, handleResponse, swal500 } from '../../utils/network';
import { isEqual } from 'lodash';
import { PrimaryButton } from './Button';

const useDebounce = (func, waitFor) => {
  const timeout = useRef(0);

  return useCallback(
    (...args) => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => func(...args), waitFor);
    },
    [func, waitFor]
  );
};

const ArrayAutocomplete = memo(
  ({
    label,
    value,
    field,
    options,
    error,
    handleChange,
    disabled,
    showInput = false,
    hide,
    useFieldInTextField,
  }) => {
    const [filtered, setFiltered] = useState([]);
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
      if (value) {
        setFiltered([value]);
        setCurrentValue(value);
      } else {
        setCurrentValue('');
        setFiltered(options.slice(0, 5));
      }
    }, [value, options]);

    const onInputChange = (event, text) => {
      const regex = RegExp('^' + text, 'i');
      const filteredOptions = options.filter(option => regex.test(option[field])).slice(0, 5);
      filteredOptions.sort((a, b) => (a[field] > b[field] ? 1 : -1));
      setFiltered(filteredOptions);
    };

    const handleSelect = (event, newValue) => {
      handleChange(newValue);
      setCurrentValue(newValue);
    };

    return hide ? null : showInput ? (
      <TextField
        value={(useFieldInTextField ? value[field] : value) || ''}
        label={label}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
        style={{ margin: '10px 0' }}
      />
    ) : (
      <Autocomplete
        disabled={disabled}
        value={currentValue || null}
        options={filtered}
        getOptionLabel={option => option[field] || ''}
        onInputChange={onInputChange}
        onChange={(event, newValue) => handleSelect(event, newValue)}
        autoHighlight
        noOptionsText="No hay opciones"
        openText="Abrir"
        clearText="Borrar"
        closeText="Cerrar"
        getOptionSelected={(option, value) => isEqual(option, value)}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            margin="normal"
            error={error !== undefined}
            helperText={error}
          />
        )}
      />
    );
  }
);

const ApiAutocomplete = memo(
  ({
    value,
    label,
    field,
    error,
    path,
    handleChange,
    disabled = false,
    showInput = false,
    likeLocale = true,
    dependsOn,
    textInputStyle = {},
    style = {},
    hide,
    freeSolo,
    filterIds = [],
    idField = '_id',
  }) => {
    const history = useHistory();
    const [optionLabels, setOptionLabels] = useState([]);
    const [options, setOptions] = useState([]);
    const [currentValue, setCurrentValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [previousDependsOnValue, setPreviousDependsOnValue] = useState(dependsOn);
    const basePathWithParams = path && `${path}${path.includes('?') ? '&' : '?'}page=0&size=30`;

    const fillAutocomplete = useCallback(
      newValue => {
        if (!basePathWithParams) return;
        const regex = new RegExp(' ');
        const nameParam = newValue.replace(regex, '%20');
        setLoading(true);
        const like = likeLocale ? 'likeLocale' : 'like';
        const endpoint = nameParam
          ? `${basePathWithParams}&${field}=${like}:${nameParam}`
          : basePathWithParams;
        get(endpoint)
          .then(res => handleResponse(res, { history }))
          .then(parsed => {
            const items = parsed.message.data.filter(
              i => i[field] && !filterIds.includes(i[idField])
            );
            if (freeSolo && items.length === 0) {
              setOptions([{ name: newValue }]);
              setOptionLabels([`Agregar "${newValue}"`]);
            } else {
              setOptions(items);
              setOptionLabels(items.map(option => option[field]));
            }
          })
          .catch(swal500)
          .finally(() => setLoading(false));
      },
      [basePathWithParams, field, history, likeLocale]
    );

    useEffect(() => {
      if (value && value[field]) {
        setCurrentValue(value[field]);
        setOptions([value]);
        setOptionLabels([value[field]]);
      } else if (!disabled) {
        fillAutocomplete('');
      }
    }, [disabled, field, value, fillAutocomplete]);

    useEffect(() => {
      if (dependsOn !== undefined && dependsOn !== previousDependsOnValue) {
        setPreviousDependsOnValue(dependsOn);
        setCurrentValue('');
        setOptions([]);
        setOptionLabels([]);
        handleChange('');
        fillAutocomplete('');
      }
    }, [dependsOn, disabled, fillAutocomplete, previousDependsOnValue]);

    const handleInputChange = (event, newValue) => {
      setCurrentValue(newValue);
      setLoading(true);
      setOptions([]);
      setOptionLabels([]);
      fillAutocomplete(newValue);
    };

    const onInputChange = useDebounce(handleInputChange, 400);

    const handleSelect = (event, newValue) => {
      let selected;
      if (newValue?.startsWith('Agregar')) selected = options[0];
      else selected = options.filter(o => o[field] === newValue)[0];
      if (selected || !newValue) handleChange(selected || '');
    };

    return hide ? null : showInput ? (
      <TextField
        value={value[field] || value}
        label={label}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
        style={{ margin: '10px 0' }}
      />
    ) : (
      <Autocomplete
        options={optionLabels}
        autoHighlight
        disabled={disabled}
        value={currentValue || null}
        onInputChange={onInputChange}
        onChange={(event, newValue) => handleSelect(event, newValue)}
        loading={loading}
        getOptionSelected={(option, value) => isEqual(option, value)}
        getOptionLabel={option => {
          if (freeSolo && option?.startsWith('Agregar')) return option.split('"')[1];
          else return option;
        }}
        loadingText="Cargando..."
        noOptionsText="No hay opciones"
        openText="Abrir"
        clearText="Borrar"
        closeText="Cerrar"
        freeSolo={freeSolo}
        style={style}
        renderInput={params => (
          <form>
            <input
              type="password"
              style={{
                width: 0,
                height: 0,
                visibility: 'hidden',
                position: 'absolute',
                left: 0,
                top: 0,
              }}
            />
            <TextField
              {...params}
              label={label}
              margin="normal"
              error={error !== undefined}
              helperText={error}
              style={textInputStyle}
            />
          </form>
        )}
      />
    );
  }
);

const AutocompleteAdd = memo(
  ({
    path,
    field,
    title,
    label,
    error,
    smallScreen,
    helpText,
    onAdd,
    style = {},
    likeLocale = true,
    filterIds,
    idField,
  }) => {
    const [value, setValue] = useState(null);
    const [resetValue, setResetValue] = useState(false);
    const handleAdd = () => {
      if (value._id) {
        onAdd(value);
        setResetValue(r => !r);
        setValue({});
      }
    };
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: smallScreen ? 'center' : 'flex-end',
        }}
      >
        <ApiAutocomplete
          path={path}
          field={field}
          value={value}
          title={title}
          label={label}
          handleChange={value => setValue(value)}
          error={error}
          style={{ flex: 1, marginRight: 20, marginTop: 0, ...style }}
          textStyle={{ marginTop: 0, marginBottom: 0 }}
          dependsOn={resetValue}
          likeLocale={likeLocale}
          filterIds={filterIds}
          idField={idField}
        />
        {smallScreen ? (
          <IconButton style={{ padding: 0 }} onClick={handleAdd}>
            <AddCircle color="primary" />
          </IconButton>
        ) : (
          <PrimaryButton onClick={handleAdd} style={{ padding: '2px 7px', marginBottom: 5 }}>
            Agregar
          </PrimaryButton>
        )}
        {!smallScreen && (
          <Tooltip title={helpText}>
            <HelpIcon style={{ color: '#707070', marginBottom: 10, marginLeft: 5, fontSize: 20 }} />
          </Tooltip>
        )}
      </div>
    );
  }
);

export { ApiAutocomplete, ArrayAutocomplete, AutocompleteAdd };
