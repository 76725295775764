import { useCallback, useState } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { del, handleResponse, swal500 } from '../../utils/network';

const useFormContainer = (entityProps, resetValues, deleteConfirmation) => {
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();

  const { entityName, endpoint, path } = entityProps;
  const [data, setData] = useState({});
  const [editMode, setEditMode] = useState(location.editMode || false);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isNew = id === 'new';

  const handleClose = () => {
    resetValues(data);
    history.push(path);
  };

  const handleCancel = useCallback(() => {
    resetValues(data);
    setEditMode(false);
  }, [data, resetValues]);

  const handleDelete = () => {
    Swal.fire({
      title: `Eliminar ${entityName}`,
      text: deleteConfirmation,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonText: `No, conservar ${entityName}`,
      confirmButtonText: 'Si, estoy seguro',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        Swal.update({ showCancelButton: false });
        return del(`${endpoint}/${id}`)
          .then(res => handleResponse(res, { history }))
          .then(() => history.push({ pathname: path, state: { msg: 'deleted' } }))
          .catch(err => swal500(err));
      },
    });
  };

  return {
    data,
    setData,
    editMode,
    setEditMode,
    loading,
    setLoading,
    submitting,
    setSubmitting,
    isNew,
    handleClose,
    handleCancel,
    handleDelete,
    smallScreen,
  };
};

export default useFormContainer;
