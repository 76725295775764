export default () => ({
  sectionTitle: {
    fontWeight: 600,
    color: 'grey',
    margin: 0,
    fontSize: 16,
    marginTop: 20,
    marginBottom: 10,
  },
});
