import React, { useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import { handleResponse, post, patch, swal500, get, handle400 } from '../../../utils/network';
import {
  useFormValidation,
  useGetPermissions,
  usePermissionSelector,
  useFormContainer,
  FormLayout,
} from '../../common';
import validateUser from './validator';
import UserFormComponent from './UserFormComponent';
import { MESSAGES, ENTITY_PROPS } from '../UserTable';
import styles from './styles';
import { isEqual } from 'lodash';

export const FIELDS = {
  firstName: 'Nombre',
  lastName: 'Apellido',
  email: 'Email',
  password: 'Contraseña',
  role: 'Rol',
};

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  role: {},
  permissions: [],
};

const UserFormContainer = ({ history, match, classes }) => {
  const { id } = match.params;
  const { entityName, endpoint, path } = ENTITY_PROPS;

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    setErrors,
    resetValues,
  } = useFormValidation(INITIAL_STATE, values => validateUser(values, isNew));

  const {
    data,
    setData,
    editMode,
    setEditMode,
    loading,
    setLoading,
    submitting,
    setSubmitting,
    isNew,
    handleClose,
    handleCancel,
    handleDelete,
  } = useFormContainer(ENTITY_PROPS, resetValues, MESSAGES.deleteConfirmation(values));

  const handleChangeAutocomplete = useCallback(
    (name, value) => {
      handleChange({
        target: {
          name: name,
          value: value,
        },
      });
    },
    [handleChange]
  );

  const {
    handleChangePermission,
    handleChangeCategory,
    permissions,
    setPermissions,
    getPermissionsList,
    permissionsDidChange,
  } = usePermissionSelector();

  const permissionMapper = useCallback(
    permission => ({
      ...permission,
      checked:
        values.role?.permissions?.includes(permission._id) ||
        data.permissions?.map(p => p._id).includes(permission._id),
      disabled: values.role?.permissions?.includes(permission._id),
    }),
    [data.permissions, values.role]
  );

  const { loadingPermissions, getPermissions } = useGetPermissions(
    permissionMapper,
    setPermissions
  );

  const getChangedValues = values => {
    const changedValues = [];
    Object.keys(data).forEach(key => {
      if (!isEqual(data[key], values[key])) changedValues.push(key);
    });
    if (data.role?._id !== values.role?._id) delete changedValues.role;
    if (permissionsDidChange(data.permissions) || changedValues.some(v => v === 'role')) {
      changedValues.push('permissions');
    }
    return changedValues.filter(v => v !== 'created' && v !== 'rolePermissions' && v !== 'region');
  };

  useEffect(() => {
    const getUser = () => {
      setLoading(true);
      get(`${endpoint}/${id}`)
        .then(res => handleResponse(res, { history }))
        .then(parsed => {
          if (parsed.error) history.push(path);
          else {
            setData({ ...INITIAL_STATE, ...parsed.message });
            resetValues({ ...INITIAL_STATE, ...parsed.message });
            setLoading(false);
          }
        });
    };

    getPermissions();

    if (id === 'new') {
      setData(INITIAL_STATE);
      setEditMode(true);
      setLoading(false);
    } else {
      getUser();
    }
  }, [endpoint, getPermissions, history, id, path, resetValues, setData, setEditMode, setLoading]);

  const hasChanges = () => editMode && getChangedValues(values).length > 0;

  const handleResult = e => {
    const formValid = handleSubmit(e);
    if (formValid) {
      setSubmitting(true);
      const { password, email, firstName, lastName, role } = values;
      let body = {};
      if (isNew) {
        body = {
          ...body,
          password,
          email,
          firstName,
          lastName,
          role: role._id,
          permissions: getPermissionsList,
        };
        post(endpoint, body)
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(() => history.push({ pathname: path, state: { msg: 'created' } }))
          .catch(err => swal500(err))
          .finally(() => setSubmitting(false));
      } else {
        const changedValues = getChangedValues(values);
        if (changedValues.length === 0) {
          history.push(path);
          return;
        }
        changedValues.forEach(value => {
          let changes;
          if (value === 'role') {
            changes = role._id ? { role: role._id } : { deleteRole: true };
          } else if (value === 'permissions') {
            changes = { permissions: getPermissionsList };
          } else {
            changes = { [value]: values[value] };
          }
          body = { ...body, ...changes };
        });

        patch(`${endpoint}/${data._id}`, body)
          .then(res =>
            handleResponse(res, { history }, [
              { status: 400, method: handle400(FIELDS, setErrors) },
            ])
          )
          .then(() => history.push({ pathname: path, state: { msg: 'edited' } }))
          .catch(err => swal500(err))
          .finally(() => setSubmitting(false));
      }
    }
  };

  return (
    <FormLayout
      handleClose={handleClose}
      loading={loading}
      editMode={editMode}
      submitting={submitting}
      handleSubmit={handleResult}
      handleCancel={handleCancel}
      isNew={isNew}
      entityName={entityName}
      hasChanges={hasChanges}
      handleEdit={() => setEditMode(true)}
      handleDelete={handleDelete}
    >
      <UserFormComponent
        submitting={submitting}
        editMode={editMode}
        isNewUser={isNew}
        values={values}
        errors={errors}
        handleChange={handleChange}
        handleChangeAutocomplete={handleChangeAutocomplete}
        handleBlur={handleBlur}
        loadingPermissions={loadingPermissions}
        permissions={permissions}
        handleChangePermission={handleChangePermission}
        handleChangeCategory={handleChangeCategory}
      />
    </FormLayout>
  );
};

export default withStyles(styles)(UserFormContainer);
