import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import { FIELDS } from './UserFormContainer';
import { ApiAutocomplete, PermissionsSelector, PasswordInput, TextInput } from '../../common';
import { getCookie } from '../../../utils/utils';
import styles from './styles';

const UserFormComponent = ({
  classes,
  errors,
  handleChange,
  handleChangeAutocomplete,
  handleBlur,
  loadingPermissions,
  values,
  editMode,
  isNewUser,
  permissions,
  handleChangePermission,
  handleChangeCategory,
}) => {
  const isCurrentUser = getCookie('userId') === values?._id;

  const handleChangeRole = useCallback(value => handleChangeAutocomplete('role', value), [
    handleChangeAutocomplete,
  ]);

  return (
    <>
      <p className={classes.sectionTitle} style={{ marginTop: 10 }}>
        Información de la cuenta:{' '}
      </p>
      <TextInput
        name="firstName"
        value={values.firstName}
        error={errors.firstName}
        handleChange={handleChange}
        handleBlur={handleBlur}
        label={FIELDS['firstName']}
        disabled={!editMode}
        hide={!editMode && !values.firstName}
      />
      <TextInput
        name="lastName"
        value={values.lastName}
        error={errors.lastName}
        handleChange={handleChange}
        handleBlur={handleBlur}
        label={FIELDS['lastName']}
        disabled={!editMode}
        hide={!editMode && !values.lastName}
      />
      <TextInput
        name="email"
        value={values.email}
        error={errors.email}
        handleChange={handleChange}
        handleBlur={handleBlur}
        label={FIELDS['email']}
        disabled={!editMode}
        hide={!editMode && !values.email}
      />
      {isNewUser && (
        <PasswordInput
          name="password"
          error={errors.password}
          handleChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          label={FIELDS['password']}
          hideStartAdornment
          InputProps={{
            autoComplete: 'new-password',
          }}
        />
      )}
      <ApiAutocomplete
        path="roles"
        field="role"
        value={values.role}
        label="Rol"
        handleChange={handleChangeRole}
        error={errors.role}
        showInput={!editMode || isCurrentUser}
        likeLocale={false}
        hide={!editMode && !values.role}
      />
      {(!isCurrentUser || !editMode) && (
        <PermissionsSelector
          permissions={permissions}
          editMode={editMode}
          loading={loadingPermissions}
          handleChangePermission={handleChangePermission}
          handleChangeCategory={handleChangeCategory}
        />
      )}
    </>
  );
};

export default withStyles(styles)(UserFormComponent);
