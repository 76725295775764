import React, { useCallback, useEffect, useState } from 'react';
import { del, get, handleResponse, swal500 } from '../../utils/network';
import { Loading, SuccessToast } from '../common';
import { withStyles, withTheme } from '@material-ui/core';
import * as Swal from 'sweetalert2';
import styles from './styles';
import SessionItem from './SessionItem';

const SessionsList = props => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { classes } = props;

  const getSessions = useCallback(() => {
    setLoading(true);
    get('sessions/mine')
      .then(res => handleResponse(res, props))
      .then(parsed => {
        setData(parsed.message);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        swal500(err);
      });
  }, [props]);

  useEffect(() => {
    getSessions();
  }, [getSessions]);

  const handleDelete = sessionId => {
    const { palette } = props.theme;
    Swal.fire({
      title: 'Cerrar sesión',
      text: '¿Estas seguro de que queres cerrar la sesión?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: palette.primary.main,
      confirmButtonColor: palette.state.failure,
      cancelButtonText: 'No, mantener activa',
      confirmButtonText: 'Si, estoy seguro',
    }).then(result => {
      if (result.value) {
        setDeleting(true);
        del(`sessions/${sessionId}`)
          .then(res => handleResponse(res, props))
          .then(result => {
            setDeleting(false);
            getSessions();
            setSuccessMessage('Sesion cerrada correctamente');
          })
          .catch(err => {
            setDeleting(false);
            swal500(err);
          });
      }
    });
  };

  const handleDeleteAll = () => {
    const { palette } = props.theme;
    Swal.fire({
      title: 'Cerrar sesiones',
      text: '¿Estas seguro de que queres cerrar todas las sesiones menos la actual?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: palette.primary.main,
      confirmButtonColor: palette.state.failure,
      cancelButtonText: 'No, conservarlas',
      confirmButtonText: 'Si, estoy seguro',
    }).then(result => {
      if (result.value) {
        setDeleting(true);
        del(`sessions/mine`)
          .then(res => handleResponse(res, props))
          .then(result => {
            setDeleting(false);
            getSessions();
            setSuccessMessage('Todas las sesiones fueron cerradas correctamente');
          })
          .catch(err => {
            setDeleting(false);
            swal500(err);
          });
      }
    });
  };

  const validSessions = data
    .filter(sessions => sessions.type === 'valid')
    .flatMap(s => s.sessions)
    .filter(s => !s.actual)
    .reverse();
  const invalidSessions = data
    .filter(sessions => sessions.type === 'invalid')
    .flatMap(s => s.sessions)
    .reverse();
  const actualSession = data
    .filter(sessions => sessions.type === 'valid')
    .flatMap(s => s.sessions)
    .find(s => s.actual);

  return (
    <div className={classes.root}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <p className={classes.title}>Sesión actual</p>
          <p className={classes.deleteAll} onClick={handleDeleteAll}>
            Cerrar todas las demás sesiones
          </p>
          <SessionItem
            session={actualSession}
            last
            handleDelete={handleDelete}
            deleting={deleting}
          />
          <div className={classes.divider} />
          <p className={classes.title}>Sesiones activas</p>
          {validSessions.length > 0 ? (
            validSessions.map((session, index) => (
              <SessionItem
                key={index}
                session={session}
                last={index === validSessions.length - 1}
                handleDelete={handleDelete}
                deleting={deleting}
              />
            ))
          ) : (
            <p className={classes.noContent}>No tiene otras sesiones activas.</p>
          )}
          <div className={classes.divider} />
          <p className={classes.title}>Sesiones inactivas</p>
          {invalidSessions.length > 0 ? (
            invalidSessions.map((session, index) => (
              <SessionItem
                key={index}
                session={session}
                last={index === invalidSessions.length - 1}
              />
            ))
          ) : (
            <p className={classes.noContent}>No tiene sesiones inactivas.</p>
          )}
        </>
      )}
      <SuccessToast
        open={successMessage !== ''}
        onClose={() => setSuccessMessage('')}
        text={successMessage}
      />
    </div>
  );
};

export default withTheme(withStyles(styles)(SessionsList));
