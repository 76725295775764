import React, { memo } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  FormLabel,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const CustomAccordion = memo(
  ({ category, handleChangeCategory, handleChangePermission, editMode }) => {
    const allPermissionsChecked = category.permissions.every(p => p.checked);
    const somePermissionChecked = category.permissions.some(p => p.checked);
    return (
      (editMode || somePermissionChecked) && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {editMode ? (
              <FormControlLabel
                control={
                  <Checkbox
                    indeterminate={somePermissionChecked && !allPermissionsChecked}
                    checked={allPermissionsChecked}
                    onChange={e => handleChangeCategory(category.category, e)}
                    name={category.category}
                  />
                }
                label={category.category}
              />
            ) : (
              <FormLabel>{category.category}</FormLabel>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              {category.permissions.map(
                permission =>
                  (editMode || permission.checked) &&
                  (permission.disabled && editMode ? (
                    <Tooltip title="Permiso asociado al rol" placement="right" key={permission._id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!editMode || permission.disabled}
                            checked={permission.checked ? permission.checked : false} //for controlled component
                            onChange={e => handleChangePermission(category.category, e)}
                            name={permission.name}
                          />
                        }
                        label={permission.description}
                      />
                    </Tooltip>
                  ) : (
                    <FormControlLabel
                      key={permission._id}
                      control={
                        <Checkbox
                          disabled={!editMode || permission.disabled}
                          checked={permission.checked ? permission.checked : false} //for controlled component
                          onChange={e => handleChangePermission(category.category, e)}
                          name={permission.name}
                        />
                      }
                      label={permission.description}
                    />
                  ))
              )}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      )
    );
  }
);

function PermissionsSelector({
  permissions = [],
  loading,
  handleChangeCategory,
  handleChangePermission,
  editMode,
}) {
  const somePermissionChecked =
    permissions.filter(category => category.permissions.some(p => p.checked)).length > 0;
  return (
    <div>
      {(somePermissionChecked || editMode) && (
        <Typography
          style={{
            fontSize: 16,
            margin: '15px 0',
          }}
        >
          Permisos asignados
        </Typography>
      )}
      {loading ? (
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
          <CircularProgress color="primary" size={20} />
          <span style={{ paddingLeft: 10 }}>Obteniendo permisos...</span>
        </div>
      ) : permissions.length > 0 ? (
        permissions.map(category => (
          <CustomAccordion
            key={category.category}
            category={category}
            handleChangeCategory={handleChangeCategory}
            handleChangePermission={handleChangePermission}
            editMode={editMode}
          />
        ))
      ) : (
        <Typography>No posee permisos</Typography>
      )}
    </div>
  );
}

export default memo(PermissionsSelector);
