import React, { Component } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Button,
  ListItemIcon,
  Link,
  IconButton,
} from '@material-ui/core';
import { Person, ArrowDropDown, ExitToApp, Menu as MenuIcon } from '@material-ui/icons';
import withTheme from '@material-ui/core/styles/withTheme';
import { deleteCookie, getCookie } from '../../utils/utils';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      smallScreen: false,
      showMenu: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 630, showMenu: window.innerWidth <= 959 });
  }

  handleLogout = () => {
    deleteCookie('isLogged');
    deleteCookie('routeOptions');
    deleteCookie('userId');
    deleteCookie('name');
    this.props.logout();
  };

  handleClose = () => this.setState({ anchorEl: null });

  handleMenu = event => this.setState({ anchorEl: event.currentTarget });

  render() {
    let open = Boolean(this.state.anchorEl);
    return (
      <AppBar style={{ zIndex: 900 }} position="static">
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            {this.state.showMenu && (
              <IconButton
                onClick={() => this.props.handleDrawerToggle()}
                style={{ padding: '0 10px 0 0' }}
              >
                <MenuIcon style={{ color: 'white' }} />
              </IconButton>
            )}
            <Link href={this.props.link}>
              <Typography variant="h6" style={{ color: 'white' }}>
                {!this.state.smallScreen && this.props.title}
              </Typography>
            </Link>
          </div>
          <div>
            <Button
              onClick={this.handleMenu}
              color="inherit"
              startIcon={<Person />}
              endIcon={<ArrowDropDown />}
              style={{
                paddingTop: this.state.smallScreen ? 10 : 12,
                paddingBottom: this.state.smallScreen ? 10 : 12,
                color: 'white',
              }}
            >
              {getCookie('name')}
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={this.state.anchorEl}
              elevation={1}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={open}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleLogout} style={{ padding: 12 }}>
                <ListItemIcon>
                  <ExitToApp style={{ color: 'grey' }} />
                </ListItemIcon>
                <Typography variant="inherit" style={{ color: 'rgba(0,0,0,0.7)' }}>
                  Cerrar sesión
                </Typography>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withTheme(Header);
