import React from 'react';
import { Facebook, GitHub, Twitter } from '@material-ui/icons';

const oauthOptions = [
  {
    label: 'Iniciar sesión con Facebook',
    backgroundColor: '#3b5998',
    icon: <Facebook />,
    endpoint: 'auth/login/oauth2/facebook',
  },
  {
    label: 'Iniciar sesión con Twitter',
    backgroundColor: '#00acee',
    icon: <Twitter />,
    endpoint: 'auth/login/oauth2/twitter',
  },
  {
    label: 'Iniciar sesión con GitHub',
    backgroundColor: '#24292e',
    icon: <GitHub />,
    endpoint: 'auth/login/oauth2/github',
  },
  {
    label: 'Iniciar sesión con Google',
    backgroundColor: '#DB4437',
    icon: <img src="logo-google.png" width={20} height={20} alt="logo-google" />,
    endpoint: 'auth/login/oauth2/google',
  },
];

export default oauthOptions;
