import React from 'react';
import { Button, withStyles, Paper } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/es';
import styles from './styles';

const SessionItem = ({ session, last, classes, handleDelete, deleting }) => {
  moment.locale('es');
  return (
    <Paper className={classes.itemContainer}>
      <div style={{ color: 'grey' }}>
        <p>
          <b>Creada:</b> {moment(session.created).fromNow()}
        </p>
        <p>
          <b>Navegador:</b> {session.userAgent}
        </p>
      </div>
      {!session.invalid && (
        <div className={classes.buttonContainer}>
          <Button color="primary" onClick={() => handleDelete(session._id)} disabled={deleting}>
            Cerrar sesión
          </Button>
        </div>
      )}
    </Paper>
  );
};

export default withStyles(styles)(SessionItem);
