import { Group, Person, Phonelink, Security, ExitToApp } from '@material-ui/icons';

const drawerOptions = [
  {
    label: 'Usuarios',
    value: 0,
    path: '/home/users',
    icon: Group,
    permissionNeeded: 'viewUsers',
    section: 1,
  },
  {
    label: 'Roles',
    value: 1,
    path: '/home/roles',
    icon: Security,
    permissionNeeded: 'viewRoles',
    section: 1,
  },
  {
    label: 'Perfil',
    value: 2,
    path: '/home/profile',
    icon: Person,
    permissionNeeded: 'none',
    section: 0,
  },
  {
    label: 'Sesiones',
    value: 3,
    path: '/home/sessions',
    icon: Phonelink,
    permissionNeeded: 'none',
    section: 0,
  },
  {
    label: 'Cerrar sesión',
    value: 4,
    path: 'logout',
    icon: ExitToApp,
    permissionNeeded: 'none',
    section: 0,
  },
];

const drawerSections = [
  {
    value: 0,
    label: 'Mi cuenta',
  },
  {
    value: 1,
    label: 'Administración',
  },
];

export { drawerOptions, drawerSections };
