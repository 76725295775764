import React from 'react';
import { Layout } from '../components/common';
import { Route, Redirect, Switch } from 'react-router-dom';
import UserTable from '../components/Users/UserTable';
import UserFormContainer from '../components/Users/UsersForm/UserFormContainer';
import RolesTable from '../components/Roles/RolesTable';
import RolesForm from '../components/Roles/RolesForm/RolesForm';
import SessionsList from '../components/Sessions/SessionsList';
import ProfileFormContainer from '../components/Profile/ProfileFormContainer';
import { getCookie } from '../utils/utils';

export default function Home(props) {
  const isLogged = getCookie('isLogged');
  return !isLogged ? (
    <Redirect from="/home/*" to="/login" />
  ) : (
    <Layout history={props.history}>
      <Switch>
        <Route exact path="/home/users" component={UserTable} />
        <Route exact path="/home/users/:id" component={UserFormContainer} />
        <Route exact path="/home/roles" component={RolesTable} />
        <Route exact path="/home/roles/:id" component={RolesForm} />
        <Route exact path="/home/sessions" component={SessionsList} />
        <Route exact path="/home/profile" component={ProfileFormContainer} />
        <Redirect from="/home" to={JSON.parse(getCookie('routeOptions'))[0].path} exact />
        <Redirect from="/home/*" to="/404" />
      </Switch>
    </Layout>
  );
}
