export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
export const NAME_REGEX = /^([a-zA-ZÀ-úÜü]+\s?)+$/;

export default (values, isNewUser) => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = 'Complete el nombre';
  } else if (!NAME_REGEX.test(values.firstName)) {
    errors.firstName = 'El nombre debe ser un nombre válido';
  }

  if (!values.lastName) {
    errors.lastName = 'Complete el apellido';
  } else if (!NAME_REGEX.test(values.lastName)) {
    errors.lastName = 'El apellido debe ser un apellido válido';
  }

  if (isNewUser) {
    if (!values.password) {
      errors.password = 'Complete la contraseña';
    } else if (values.password.length < 8 || values.password.length > 20) {
      errors.password = 'La contraseña debe tener entre 8 y 20 caracteres';
    } else if (!PASSWORD_REGEX.test(values.password)) {
      errors.password =
        'La contraseña debe tener al menos un caracter en minúscula, uno en mayúscula y un numéro';
    }
  }

  if (!values.email) {
    errors.email = 'Complete el email';
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = 'El mail debe ser un mail válido';
  }

  return errors;
};
