export default theme => ({
  background: {
    backgroundColor: '#efefef',
    [theme.breakpoints.down(516)]: {
      backgroundColor: 'white',
    },
  },
  gridLogin: {
    minHeight: '100vh',
  },
  title: {
    marginBottom: 15,
    color: '#4d4d4d',
    marginTop: 10,
    fontSize: 30,
    paddingBottom: 5,
  },
  formLogin: {
    marginTop: '2.5%',
    marginBottom: '2.5%',
    width: 400,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '35px 50px 35px 50px',
    borderRadius: 5,
    boxShadow: '0px 0px 12px -8px rgba(0, 0, 0, 0.75)',
    [theme.breakpoints.down(516)]: {
      minHeight: '80vh',
      maxWidth: '80%',
      padding: 10,
      margin: 0,
      boxShadow: 'none',
      borderRadius: 0,
    },
    backgroundColor: 'white',
  },
  inputLogin: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputIcon: {
    marginRight: 5,
    color: 'grey',
  },
  signInButton: {
    marginTop: 10,
    marginBottom: 15,
    color: 'white',
  },
  rememberContainer: {
    [theme.breakpoints.up(375)]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  loadingContainer: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    position: 'fixed',
    zIndex: 1,
  },
  forgetPassword: {
    border: 'none',
    color: '#20485d',
    fontSize: 14,
    cursor: 'pointer',
    margin: 0,
  },
  oauthButton: {
    margin: '6px 0',
  },
  loadingToken: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },
  errorContainer: {
    display: 'flex',
    padding: 10,
    fontSize: 14,
    justifyContent: 'flex-start',
    marginBottom: 10,
    alignItems: 'center',
    borderRadius: '.25rem',
  },
  errorIcon: {
    fontSize: 22,
    padding: '0 5px',
  },
  errorMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 10,
  },
  errorUuid: {
    margin: 0,
    textAlign: 'left',
    paddingBottom: 5,
  },
  errorText: {
    margin: 0,
    textAlign: 'left',
  },
});
